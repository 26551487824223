import davidz from "./thumbnail/davidz.jpg";
import sarahc from "./thumbnail/sarahc.jpg";
import tiah from "./thumbnail/tiah.jpg";
import marcs from "./thumbnail/marcs.jpg";
import vincenzob from "./thumbnail/vincenzob.jpg";
import conner from "./thumbnail/conner.jpg";
import scher from "./thumbnail/benscott.jpg";
import judith from "./thumbnail/ellaott.jpg";
import yuanyuan from "./thumbnail/yuanyuan.png";
import efrain from "./thumbnail/efrain.png";
import whitet1 from "./thumbnail/whitet1.jpg";
import "./staffTeam.css";
const StaffTeam = () => {
  return (
    <div className="staff-team-page">
      <div className="staff-team-container">
        <div className="wsite-multicol">
          <table className="wsite-multicol-table">
            <tbody>
              <tr>
                <td colspan="4" align="center" className="faculty-team-table">
                  <span
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "4vmin",
                      color: "#ff0000",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                  >
                    Faculty Team
                  </span>
                </td>
              </tr>
              <tr>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={davidz} style={{ maxWidth: "90%" }} alt="davidz" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>David Zook</b>
                  <br />
                  <br />
                  - SVT co-founder and management member
                  <br />- Chief coach to SVT varsity team of VEX competitive
                  robotics &amp; Drones
                  <br />- Many years of marketing experience in the software
                  industry
                  <br />- MBA of University of Washington; Bachelor degree from
                  Evergreen State College
                </td>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={sarahc} style={{ maxWidth: "90%" }} alt="sarahc" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Dr. Sarah&nbsp;Clayton</b>
                  <br />
                  <br />
                  - Sr. teacher in English, Writing and Social Study
                  <br />- Student engagement specialist
                  <br />- Former lecturer at University of Washington and senior
                  news editor
                  <br />- Ph.D. on East Asia language and culture study from
                  University of Washington in Seattle; master’s degree from
                  Washington University in St. Louis
                </td>
              </tr>
              <tr>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={tiah} style={{ maxWidth: "90%" }} alt="tiah" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Tiah Rodriguez</b>
                  <br />
                  <br />
                  - Lead English instructor
                  <br />- Ex English teacher, journalism advisor, and department
                  head at Bellevue Highschool and Henry M. Jackson Highschool.
                  <br />- B.A. in Classical Civilizations from UC Berkeley;
                  M.Ed. in Secondary Endorsement in English Language Arts from
                  University of Washington; M.A. in Classical Civilizations from
                  Florida State University
                </td>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={marcs} style={{ maxWidth: "90%" }} alt="marcs" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Marc&nbsp;Schleh</b>
                  <br />
                  <br />
                  - SVT chief instructor for science courses; highly experienced
                  in biology and chemistry and physics, plus Math
                  <br />- Engaged in academic and industry laboratory for a
                  decade, author of several research papers
                  <br />- Major at biology and physiology, studied in University
                  of Washington and Georgetown University.
                </td>
              </tr>
              <tr>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img
                    src={vincenzob}
                    style={{ maxWidth: "90%" }}
                    alt="vincenzob"
                  />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Vincenzo Billett</b>
                  <br />
                  <br />
                  - Teacher of Physics and Math
                  <br />
                  <br />
                  -&nbsp;Major of computer science, University of Washington
                </td>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={conner} style={{ maxWidth: "90%" }} alt="conner" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Conner Whitney</b>
                  <br />
                  <br />
                  - Teaching US/World History and US Government &amp; Politics
                  <br />- Overseas teaching and training experience.
                  <br />- Dual degrees in Political Science and Law from
                  University of Washington
                </td>
              </tr>
              <tr>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={scher} style={{ maxWidth: "90%" }} alt="scher" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Benjamine Scott</b>
                  <br />
                  <br />
                  - SVT English teacher for English and ELL and Social courses.
                  <br />- 10-year of cumulative experience in creating positive experiences and learning opportunities.
                  <br />- Ever taught as an expatriate teacher in Asian counties.
                  <br />- Master in Education (Academic Excellence Scholarship Recipient) from Pacific Lutheran University; Bachelor of Arts in Liberal Arts with the Evergreen State College.
                </td>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={judith} style={{ maxWidth: "90%" }} alt="judith" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Ella Ott</b>
                  <br />
                  <br />
                  - SVT inspiring teacher at high school ELA courses & ESL.
                  <br />- Accumulated 6-year English/ESL teaching & tutoring experience in high school & colleges.
                  <br />- Ex instructor for mid and high school students in UW Robinson center.
                  <br />- Master in TESOL from University of Washington in Seattle; B.A. of Linguistics by University of Tennessee Knoxville
                </td>
              </tr>
              <tr>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img
                    src={yuanyuan}
                    style={{ maxWidth: "90%" }}
                    alt="yuanyuan"
                  />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Yuanyuan Lin</b>
                  <br />
                  <br />
                  - Chinese language instructor
                  <br />
                  - Experienced travel writer and translator.
                  <br />- BA in English language and literature from the
                  Shanghai Foreign Study University; BA in Geography from the
                  University of Washington.
                </td>
                <td align="center" className="staff-img-container">
                  <br />
                  <br />
                  <img src={efrain} style={{ maxWidth: "90%" }} alt="efrain" />
                  <br />
                </td>
                <td align="left" className="staff-description">
                  <br />
                  <br />
                  <b>Efrain A. Diaz Laboy</b>
                  <br />
                  <br />
                  - Spanish and Math-in-Spanish Instructor
                  <br />
                  - Experience teaching K-12 and College Spanish and Math in
                  University of Turabo of Puerto Rico and Homeschool in
                  Washington
                  <br />- Master in Math Education from Interamericana
                  University of Puerto Rico; Bachelor’s in Education and
                  Teaching from the University of Puerto Rico
                </td>
              </tr>
            </tbody>
          </table>

          <hr style={{ width: "100%", clear: "both", visibility: "hidden" }} />

          <div className="wsite-multicol">
            <table className="wsite-multicol-table">
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    align="center"
                    style={{ width: "100%", padding: "0 0 10 0px" }}
                  >
                    <span
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "4vmin",
                        color: "#ff0000",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Tony White
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%", padding: "0 15px" }}>
                    <div
                      className="staff-description"
                      style={{ display: "block" }}
                    >
                      An award-winning author, filmmaker, animator and educator
                      with over 200 commercials, 2 TV Specials for PBS
                      Television, numerous Short Films and the title sequence to
                      his credit. His first ever short Film, "HOKUSAI ~ An
                      Animated Sketchbook" won a British Academy Award.
                      <br />
                      <br />
                      Tony ran his own award-winning London studio for 20
                      years；For the next 14 years he has been an animation and
                      production instructor at a highest college levels. Having
                      been the Dean at 'DigiPen Institute of Technology' and the
                      chief instructor at the 'Academy of Interactive
                      Entertainment'.
                      <br />
                      <br />
                      Tony is author of several respected books on animation,
                      including 'The Animator's Workbook', 'Animation from
                      Pencils to Pixels', 'How To Make Animated Films', 'The
                      Animator's Notebook' and 'The Animator's Sketchbook'.
                    </div>
                  </td>
                  <td
                    align="center"
                    style={{ width: "50%", padding: "0 15px" }}
                  >
                    <img
                      src={whitet1}
                      style={{ maxWidth: "60%" }}
                      alt="whitet1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr style={{ width: "100%", clear: "both", visibility: "hidden" }} />

          <hr style={{ width: "100%", clear: "both", visibility: "hidden" }} />
        </div>
      </div>
    </div>
  );
};

export default StaffTeam;
