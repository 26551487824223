// UserLogin.js
import React, { useState } from "react";
import "./userLogin.css";
import { GlobalConstant } from "../../GlobalConstant";
import axiosClient from "../../axiosClient";
import { useStateContext } from "../../contexts/contextProvider";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ForgotPassword from "./forgotPassword";
import Cookies from "js-cookie";

function UserLogin({ setShowModal }) {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const { setToken } = useStateContext();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosClient.post(`/login`, loginData);
      setToken(response.data.accessToken);

      //Set cookies received from the backend
      response.data.cookies.forEach((cookie) => {
        Cookies.set(cookie.Name, cookie.Value, {
          domain: cookie.Domain,
          path: cookie.Path,
          secure: cookie.Secure,
          sameSite: cookie.SameSite,
          expires: undefined,
        });
      });

      setShowModal(false);
      window.location.reload();
      // console.log(response);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        console.error("Error login:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  return (
    <div className="login-modal">
      <div className="login-form-container">
        <button
          className="close-icon-button"
          onClick={() => setShowModal(false)}
        >
          <ClearOutlinedIcon />
        </button>
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={loginData.username}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={loginData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="links">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowForgotPasswordModal(true);
              }}
            >
              Forgot Password?
            </a>
          </div>
          <div className="form-actions">
            <button type="submit" className="login-button">
              Log In
            </button>
          </div>
        </form>
      </div>
      {showForgotPasswordModal && (
        <ForgotPassword
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        />
      )}
    </div>
  );
}

export default UserLogin;
