export const GlobalConstant = {
  // REACT_APP_DEV_BACKENDURL: `http://127.0.0.1:8000/api`,  // dev testing locally
  REACT_APP_DEV_BACKENDURL: `https://seattlevt.org/classflex_dev/Backend/public/api`, // dev testing on server
  REACT_APP_PROD_BACKENDURL: `https://seattlevt.org/Backend/public/api`,
  REACT_APP_BASE_BACKENDURL: `http://127.0.0.1:8000`,
  GIBBON_URL:
    process.env.REACT_APP_ENV === "production"
      ? "https://seattlevt.org/HS/"
      : "https://seattlevt.org/staging/index.php",
};

export const AllTimeZones = [
  "GMT-1200 (International Date Line West)",
  "GMT-1100 (Coordinated Universal Time)",
  "GMT-1000 (Hawaii Standard Time)",
  "GMT-0900 (Alaska Standard Time)",
  "GMT-0800 (Pacific Standard Time)",
  "GMT-0700 (Mountain Standard Time)",
  "GMT-0600 (Central Standard Time)",
  "GMT-0500 (Eastern Standard Time)",
  "GMT-0400 (Atlantic Standard Time)",
  "GMT-0300 (Argentina Standard Time)",
  "GMT-0200 (South Georgia Standard Time)",
  "GMT-0100 (Azores Standard Time)",
  "GMT+0000 (Coordinated Universal Time)",
  "GMT+0100 (Central European Time)",
  "GMT+0200 (Eastern European Time)",
  "GMT+0300 (Moscow Standard Time)",
  "GMT+0400 (Gulf Standard Time)",
  "GMT+0500 (Pakistan Standard Time)",
  "GMT+0600 (Bangladesh Standard Time)",
  "GMT+0700 (Indochina Time)",
  "GMT+0800 (China Standard Time)",
  "GMT+0900 (Japan Standard Time)",
  "GMT+1000 (Australian Eastern Standard Time)",
  "GMT+1100 (Solomon Islands Time)",
  "GMT+1200 (New Zealand Standard Time)",
];
