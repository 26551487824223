/* eslint-disable jsx-a11y/img-redundant-alt */
import "./home.css";
import React from "react";
import Card from "react-bootstrap/Card";
import welcomeImg from "./images/14.jpg";
import davidz from "./thumbnail/davidz.jpg";
import sarahc from "./thumbnail/sarahc.jpg";
import tiah from "./thumbnail/tiah.jpg";
import marcs from "./thumbnail/marcs.jpg";
import vincenzob from "./thumbnail/vincenzob.jpg";
import conner from "./thumbnail/conner.jpg";
import ellaott from "./thumbnail/ellaott.jpg";
import benscott from "./thumbnail/benscott.jpg";
import zane from "./thumbnail/zane.jpg";
import whitet1 from "./thumbnail/whitet1.jpg";
import background from "./images/1200px-Space_Needle002.jpg";

import {
  SchoolOutlined,
  PublicOutlined,
  GroupsOutlined,
  ComputerOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const Home = () => {
  const style = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    minHeight: "100vh",
  };
  const cardData = [
    {
      title: "HIGH SCHOOL DIPLOMA",
      content:
        "Here, students are placed in an increasingly diverse, interconnected and shrinking world, with more cooperation, tolerance and cross-cultural communication. . .",
      link: `/academy`,
      icon: <SchoolOutlined style={{ fontSize: "6vmin" }} />,
    },
    {
      title: "LEARNING CENTER",
      content:
        "Test prep and short courses: <br>- AP cycle<br>- ACT, SAT prep<br>- Writing and Reading",
      link: `https://seattlevt.org/achance/#/`,
      icon: <PublicOutlined style={{ fontSize: "6vmin" }} />,
    },
    {
      title: "S.T.E.A.M. PROJECTS",
      content:
        "Aiming at growing the society and industry demanding future young talents; taking advantage of Greater Seattle area’s high-tech concentration . . .",
      link: `/steam`,
      icon: <ComputerOutlined style={{ fontSize: "6vmin" }} />,
    },
    {
      title: "INTERNATIONAL STUDENTS CENTER",
      content:
        "International student application package, international student programs - wholistic college planning & execution . . .",
      link: `/application-process`,
      icon: <GroupsOutlined style={{ fontSize: "6vmin" }} />,
    },
  ];

  const staffData = [
    { name: "Dr. Sarah Clayton", img: sarahc },
    { name: "Tony White", img: whitet1 },
    { name: "David Zook", img: davidz },
    { name: "Marc Schleh", img: marcs },
    { name: "Tiah Rodriguez", img: tiah },
    { name: "Conner Whitney", img: conner },
    { name: "Ella Ott", img: ellaott },
    { name: "Vincenzo Billet", img: vincenzob },
    { name: "Benjamine Scott", img: benscott },
    { name: "Zane Zook", img: zane },
  ];

  function HomeCard({ data, children }) {
    return (
      <Card
        style={{
          width: "24vmin",
          height: "45vmin",
          margin: "0.7vmin",
        }}
      >
        <span>{data.icon}</span>
        <Link to={data.link}>
          <Card.Body>
            <h4>{data.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: data.content }} />
          </Card.Body>
        </Link>
        <Card.Body>
          <h6>
            <Link to={data.link}>MORE</Link>
          </h6>
        </Card.Body>
      </Card>
    );
  }

  function MissionDisplay() {
    return (
      <div className="mission-display">
        <div className="mission-container">
          <hr
            style={{
              height: "0.4vmin",
              width: "90%",
              border: "1px solid #ccc",
              backgroundColor: "#ccc",
            }}
          ></hr>
          <h1
            style={{
              fontFamily: "Arial, sans-serif",
              color: "#400040",
              fontSize: "3vmin",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Mission Statement
          </h1>
          <h2
            style={{
              fontFamily: "Arial, sans-serif",
              color: "#4b6296",
              fontSize: "2.5vmin",
              fontWeight: "normal",
              textAlign: "center",
              width: "90%",
              margin: "4vmin",
              lineHeight: "1.1",
            }}
          >
            At Seattle VocTech, we strive to help each student develop their
            unique abilities and interests. Our approach to teaching and
            learning is holistic. In addition to providing a standard core
            curriculum, we partner with students to help them follow their
            curiosities. By helping students integrate these interests into
            larger goals, we promote intellectual development that supports
            real-world goals. This two-pronged approach to learning cultivates
            each student as an individual with unique hopes and abilities.
          </h2>
          <hr
            style={{
              height: "0.4vmin",
              width: "90%",
              border: "1px solid #ccc",
              backgroundColor: "#ccc",
            }}
          ></hr>
        </div>
      </div>
    );
  }

  function WelcomeDisplay() {
    return (
      <div className="welcome-display">
        <div className="welcome-container">
          <div className="welcome-table">
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <h1
                      style={{
                        fontFamily: "Arial, sans-serif",
                        color: "#400040",
                        fontSize: "3.5vmin",
                        fontWeight: "bold",
                        textAlign: "left",
                        margin: "10px",
                        lineHeight: "1.1",
                      }}
                    >
                      A truly welcoming community.{" "}
                    </h1>
                  </td>
                </tr>
                <tr>
                  <td className="welcome-table-col">
                    <img src={welcomeImg} alt="Picture" />
                  </td>
                  <td className="welcome-table-col">
                    <div className="welcome-table-col-paragraph">
                      The key is our individualized approach, which frees
                      children to reach their personal potential. Our students
                      are liberated from rigid schedules, classes that move too
                      fast or too slow, overcrowded classrooms, teaching to the
                      test, and many other factors that typically stand in the
                      way of academic engagement and success. Rather we use our
                      award-winning digital curriculum, developed by cognitive
                      scientists and master educators and tested on thousands of
                      students to personalize each student's program.{" "}
                    </div>
                    <br />
                    <a href={`/welcome-letter`}>More on Welcome Message</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  function StaffDisplay({ staffs, children }) {
    const rows = [];
    for (let i = 0; i < staffs.length; i += 4) {
      rows.push(staffs.slice(i, i + 4));
    }
    return (
      <div className="staff-display">
        <div className="staff-display-container">
          <h4 className="staff-display-title">Staff Team </h4>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="staff-row">
              {row.map((staff, staffIndex) => {
                return (
                  <div className="staff-col">
                    <div key={staffIndex} className="staff-card">
                      <div>
                        <img src={staff.img} alt={staff.name} />
                      </div>
                      <div className="staff-name">{staff.name}</div>
                    </div>
                  </div>
                );
              })}

              {row.length < 4 &&
                Array.from({ length: 4 - row.length }).map((_, index) => (
                  <div key={`empty-${index}`} className="staff-col"></div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="home-page" style={style}>
      <div className="cards">
        <div className="card-row">
          {cardData.map((item, index) => (
            <HomeCard key={index} data={item} children={undefined} />
          ))}
        </div>
      </div>
      <MissionDisplay />
      <WelcomeDisplay />
      <StaffDisplay staffs={staffData} children={undefined} />
    </div>
  );
};

export default Home;
