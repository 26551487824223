import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimezoneSelect, {
  useTimezoneSelect,
  allTimezones,
} from "react-timezone-select";
import axiosClient from "../../axiosClient";
import ClassRecurrenceModal from "./classRecurrenceModal";
import "./createEditClassModal.css";

function CreateEditClassModal({
  setShowModal,
  courseRequest,
  requestType = "",
  handleFetch,
}) {
  const [showRecurrence, setShowRecurrence] = useState(false);
  const [scheduleToEdit, setScheduleToEdit] = useState({});
  const [handleDataFromRecurrence, setHandleDataFromRecurrence] = useState(
    () => {}
  );
  const [loading, setLoading] = useState(true);
  const [allCourses, setAllCourses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState({});
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [classForm, setClassForm] = useState({
    purpose: "tutorial",
    departmentName: "",
    courseName: "",
    courseID: "",
    className: "",
    student: null,
    studentID: "",
    teacher: "",
    teacherID: "",
    startTime: "",
    startDate: "",
    endTime: "",
    endDate: "",
    timeZone: "",
    interval: "",
    scheduleType: "once",
    locationID: "",
    frequency: "",
    numOccurrences: "",
    byDay: "",
    byDate: "",
    weekDays: [],
    byMonth: "",
    svtCSID: "",
  });

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
  };
  const { parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const handleMakeRecurringSchedule = (data, handleDataFromRecurrence) => {
    setScheduleToEdit(data);
    setHandleDataFromRecurrence(() => handleDataFromRecurrence);
    setShowRecurrence(true);
  };

  const convert24to12 = (time) => {
    // Extract hours and minutes from the time string
    const hours = parseInt(time.slice(0, 2));
    const minutes = time.slice(3, 5);

    // Determine AM or PM
    const amOrPm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const twelveHourTime = `${hours % 12 || 12}:${minutes} ${amOrPm}`;

    return twelveHourTime;
  };

  const convertToOrder = (value) => {
    switch (value) {
      case 0:
      case "0":
        return "last";
      case 1:
      case "1":
        return "first";
      case 2:
      case "2":
        return "second";
      case 3:
      case "3":
        return "third";
      case 4:
      case "4":
        return "fourth";
      default:
        return "";
    }
  };

  useEffect(() => {
    var student;
    if (courseRequest && courseRequest?.svtRequestID) {
      student = allStudents.find(
        (student) => student.gibbonPersonID === courseRequest?.gibbonPersonID
      );
    }
    const current = new Date();
    const date = current.toISOString().split("T")[0];
    const startTime = current.toLocaleTimeString("it-IT").substring(0, 5);
    current.setHours(current.getHours() + 1);
    const endTime = current.toLocaleTimeString("it-IT").substring(0, 5);
    if (courseRequest && courseRequest?.gibbonCourseClassID) {
      if (requestType === "registered") {
        //edit a registered class
        setClassForm((prevForm) => ({
          ...prevForm,
          courseName: courseRequest?.courseName,
          courseID: courseRequest?.gibbonCourseID,
          className: courseRequest?.className,
          student: courseRequest?.students,
          teacherID:
            courseRequest?.teachers.length !== 0
              ? courseRequest?.teachers[0].gibbonPersonID
              : "",
          startDate: courseRequest?.startDate,
          startTime: courseRequest?.startTime,
          endDate: courseRequest?.endDate,
          endTime: courseRequest?.endTime,
          gibbonCourseClassID: courseRequest?.gibbonCourseClassID,
          timeZone: courseRequest?.timeZone,
        }));
      } else {
        //edit a upcoming class
        setClassForm((prevForm) => ({
          ...prevForm,
          courseName: courseRequest?.courseName,
          courseID: courseRequest?.gibbonCourseID,
          className: courseRequest?.className,
          student: courseRequest?.students,
          teacherID:
            courseRequest?.teachers.length !== 0
              ? courseRequest?.teachers[0].gibbonPersonID
              : "",
          locationID: courseRequest?.space.gibbonSpaceID,
          startDate: courseRequest?.startDate,
          originalDate: courseRequest?.startDate,
          startTime: courseRequest?.startTime,
          endDate: courseRequest?.endDate,
          endTime: courseRequest?.endTime,
          timeZone: courseRequest?.timeZone,
          svtCSID: courseRequest?.svtCSID,
          gibbonCourseClassID: courseRequest?.gibbonCourseClassID,
          // : "GMT-0800 (Pacific Standard Time)",
        }));
      }
    } else {
      // create a new class from a request or by admin directly

      setClassForm((prevForm) => ({
        ...prevForm,
        startDate: date,
        startTime: startTime,
        endDate: date,
        endTime: endTime,
        timeZone: "America/Los_Angeles", //"GMT-0800 (Pacific Standard Time)",
      }));
      if (courseRequest && courseRequest?.svtRequestID) {
        //create a new class from a request
        setClassForm((prevForm) => ({
          ...prevForm,
          purpose: courseRequest?.purpose,
          courseName: courseRequest?.courseName,
          courseID: courseRequest?.gibbonCourseID,
          student: student,
          studentID: courseRequest?.gibbonPersonID,
        }));
      }
    }
    setNewSchedule({ ...classForm });
  }, [allStudents, courseRequest]);

  useEffect(() => {
    const fetchData = async () => {
      //fetch all neccessary data from backend api
      try {
        const coursesResponse = await axiosClient.get(`/allcourses`);
        setAllCourses(coursesResponse.data.data);

        const studentsResponse = await axiosClient.get(`/allStudents`);
        setAllStudents(studentsResponse.data);

        const teachersResponse = await axiosClient.get(`/teachers`);
        setAllTeachers(teachersResponse.data);

        const locationsResponse = await axiosClient.get(`/locations`);
        setAllLocations(locationsResponse.data);

        if (courseRequest && courseRequest?.gibbonCourseID) {
          const departmentResponse = await axiosClient.get(
            `/departments/courseID?gibbonCourseID=${courseRequest?.gibbonCourseID}`
          );
          setClassForm((prevForm) => ({
            ...prevForm,
            departmentName: departmentResponse.data.name,
          }));
        }

        await fetchScheduleData();

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [courseRequest]);

  const fetchScheduleData = async () => {
    //fetch all neccessary data from backend api
    try {
      if (
        courseRequest &&
        courseRequest?.gibbonCourseClassID &&
        requestType === "registered"
      ) {
        //registered class
        const scheduleResponse = await axiosClient.get(
          `schedules?gibbonCourseClassID=${courseRequest?.gibbonCourseClassID}`
        );
        const scheduleData = scheduleResponse.data;

        setSchedules(scheduleData);
        if (scheduleData.length > 0) {
          setClassForm((prevForm) => ({
            ...prevForm,
            locationID: scheduleData[0].gibbonSpaceID,
          }));
        }
      }
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "courseName") {
      const selectedCourse = allCourses.find(
        (course) => course.courseID === parseInt(value)
      );
      setClassForm({
        ...classForm,
        courseName: selectedCourse ? selectedCourse.courseName : "",
        courseID: value,
      });
    } else {
      setClassForm({
        ...classForm,
        [name]: value,
      });
    }
  };

  const handleNewSchedule = async (schedule) => {
    if (
      window.confirm(
        "Do you want to change to a new schedule for this registered class for all future classes?"
      ) === true
    ) {
      var requestData = {
        ...schedule,
      };
      if (requestType === "registered") {
        requestData = {
          ...schedule,
          scheduleType: schedule.type,
          locationID: schedule.gibbonSpaceID,
        };
      }

      requestData = {
        ...requestData,
        description: getScheduleContent(requestData),
      };

      try {
        const response = await axiosClient.post(
          `/saveNewSchedule`,
          requestData
        );
        if (response.status === 200) {
          alert("New schedule saved successfully");
          await fetchScheduleData();
          await handleFetch();
        } else {
          alert("Failed to save new schedule");
        }
      } catch (error) {
        console.error("Error saving new schedule:", error);
      }
    }
  };
  const handleNewTeacher = async () => {
    const confirm = window.confirm(
      "Do you want to change to a new teacher for this registered class?"
    );
    if (confirm === true) {
      try {
        const response = await axiosClient.post(`/saveNewTeacher`, {
          gibbonCourseClassID: courseRequest?.gibbonCourseClassID,
          newTeacherID: classForm.teacherID,
          newTeacherName: `${classForm.teacher.firstName} ${classForm.teacher.surname}`,
        });
        if (response.status === 200) {
          alert("New teacher saved successfully");
          await handleFetch();
        } else {
          alert("Failed to save new teacher");
        }
      } catch (error) {
        console.error("Error saving new teacher:", error);
      }
    }
  };

  const handleNewLocation = async () => {
    const confirm = window.confirm(
      "Do you want to change to a new location for this registered class?"
    );
    if (confirm === true) {
      try {
        const response = await axiosClient.post(`/saveNewLocation`, {
          gibbonSpaceID: parseInt(classForm.locationID),
          gibbonCourseClassID: courseRequest?.gibbonCourseClassID,
          svtCSID: classForm.svtCSID,
        });
        if (response.status === 200) {
          alert("New location saved successfully");
          await handleFetch();
        } else {
          alert("Failed to save new location");
        }
      } catch (error) {
        console.error("Error saving new location:", error);
      }
    }
  };

  const handleStopClass = async () => {
    const confirm = window.confirm(
      "Do you want to stop this entire registered class?"
    );
    if (confirm === true) {
      try {
        const response = await axiosClient.post(`/stopClass`, {
          gibbonCourseClassID: courseRequest?.gibbonCourseClassID,
          gibbonPersonID: courseRequest?.currentUserID,
        });
        if (response.status === 200) {
          alert("Class stopped successfully");
          await handleFetch();
        } else {
          alert("Failed to stop class");
        }
      } catch (error) {
        console.error("Error stopping class:", error);
      }
    }
  };

  const handleNewClass = async () => {
    const confirm = window.confirm("Do you want to create a new class?");
    if (confirm === true) {
      try {
        const classDetail = {
          gibbonCourseID: classForm.courseID,
          studentFullName: `${classForm.student.firstName} ${classForm.student.surname}`,
          type: classForm.purpose,
          teacherID: classForm.teacherID,
          studentID: classForm.studentID,
          scheduleType: newSchedule.scheduleType,
          startDate: newSchedule.startDate,
          startTime: newSchedule.startTime,
          endTime: newSchedule.endTime,
          timeZone: newSchedule.timeZone,
          svtRequestID: courseRequest?.svtRequestID
            ? courseRequest?.svtRequestID
            : null,
          frequency: newSchedule.frequency,
          interval: newSchedule.interval,
          endDate:
            newSchedule.scheduleType == "once"
              ? newSchedule.startDate
              : newSchedule.endDate,
          numOccurrences: parseInt(newSchedule.numOccurrences),
          byDay: newSchedule.byDay,
          byDate: newSchedule.byDate,
          byMonth: newSchedule.byMonth,
          gibbonSpaceID: classForm.locationID,
          description: getScheduleContent(newSchedule),
        };
        console.log(classDetail);
        const response = await axiosClient.post(`/saveClass`, classDetail);
        if (response.status === 200) {
          await handleFetch();
          alert("New class created successfully");
          setShowModal(false);
        } else {
          alert("Failed to create new class");
        }
      } catch (error) {
        console.error("Error creating new class:", error);
        alert(
          "Error creating new class. Make sure all required fields are filled!"
        );
      }
    }
  };

  const ScheduleSection = () => {
    if (requestType === "registered" && schedules.length > 0) {
      return (
        <div className="schedule-section">
          {schedules.map((schedule) => (
            <div className="individual-schedule">
              {schedule.type === "once" && (
                <NormalSchedule
                  schedule={{
                    ...schedule,
                    ...schedule.meta,
                  }}
                />
              )}
              {schedule.type === "recurring" && (
                <RecurringSchedule
                  schedule={{ ...schedule, ...schedule.meta }}
                />
              )}
            </div>
          ))}
        </div>
      );
    } else {
      //console.log(newSchedule);
      return (
        <div className="schedule-section">
          <div className="individual-schedule">
            {newSchedule.scheduleType === "once" && (
              <NormalSchedule schedule={newSchedule} />
            )}
            {newSchedule.scheduleType === "recurring" && (
              <RecurringSchedule schedule={newSchedule} />
            )}
          </div>
        </div>
      );
    }
  };

  const updateSchedulesRecurring = (data) => {
    if (schedules.length > 0) {
      var changedData = schedules.find((s) => s.svtCSID === data.svtCSID);
      changedData = {
        ...changedData,
        type: "recurring",
        startDate: data.startDate,
        startTime: data.startTime,
        endDate: data.endDate,
        endTime: data.endTime,
        timeZone: data.timeZone,
        description: getScheduleContent(data),
      };
      changedData.meta = {
        ...changedData.meta,
        frequency: data.frequency,
        interval: data.interval,
        endDate: data.endDate,
        numOccurrences: data.numOccurrences,
        byDay: data.byDay,
        byDate: data.byDate,
        byMonth: data.byMonth,
      };
      // console.log(schedules);
    }
  };
  const updateSchedulesNormal = (data) => {
    if (schedules.length > 0) {
      var changedData = schedules.find((s) => s.svtCSID === data.svtCSID);
      changedData = {
        ...changedData,
        startDate: data.startDate,
        startTime: data.startTime,
        endTime: data.endTime,
        timeZone: data.timeZone,
      };
    }
  };
  function NormalSchedule({ schedule }) {
    const [currentSchedule, setCurrentSchedule] = useState(schedule);
    const [showEditNormal, setShowEditNormal] = useState(false);
    const handleScheduleChange = (e) => {
      const { name, value } = e.target;
      setCurrentSchedule((prev) => ({
        ...prev,
        [name]: value,
      }));
      schedule[name] = value;

      // if (name === "startDate") {
      //   const newDate = new Date(value).getTime();
      //   if (
      //     schedule.endDate &&
      //     newDate > new Date(schedule.endDate).getTime()
      //   ) {
      //     setCurrentSchedule((prev) => ({
      //       ...prev,
      //       endDate: value,
      //     }));

      //     schedule.endDate = value;
      //   }
      // }
      updateSchedulesNormal(schedule);
      // console.log(newSchedule);
      // console.log(schedules);
    };

    const handleDataFromRecurrence = (data) => {
      //recieve data from classRecurrenceModel
      console.log(data);
      setCurrentSchedule((prevData) => ({
        ...prevData,
        ...data,
        scheduleType: "recurring",
        description: getScheduleContent(data),
      }));
      Object.entries(data).forEach(([key, value]) => {
        schedule[key] = value;
      });
      schedule.scheduleType = "recurring";
      schedule.description = getScheduleContent(data);

      updateSchedulesRecurring(data);
    };

    return (
      <div className="schedule-row">
        {requestType !== "registered" ||
        (requestType === "registered" && showEditNormal) ? (
          <div className="normal-schedule">
            <div className="normal-schedule-row">
              <label htmlFor="start">Start: </label>

              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    height: "3vmin",
                    fontSize: "1.5vmin",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0",
                  },
                }}
                id="startDate"
                name="startDate"
                type="date"
                variant="standard"
                value={currentSchedule.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date(new Date().setDate(new Date().getDate() - 1))
                    .toISOString()
                    .slice(0, 10), // Set minimum selectable date here
                }}
                onChange={handleScheduleChange}
              />

              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    height: "3vmin",
                    fontSize: "1.5vmin",
                    width: "12vmin",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0",
                  },
                }}
                id="startTime"
                name="startTime"
                type="time"
                variant="standard"
                value={currentSchedule.startTime}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleScheduleChange}
              />

              <TimezoneSelect
                value={currentSchedule.timeZone}
                onChange={(event) => {
                  schedule.timeZone = event.value;
                  setCurrentSchedule((prev) => ({
                    ...prev,
                    timeZone: event.value,
                  }));
                }}
              />
            </div>
            <div className="normal-schedule-row">
              <label htmlFor="start">End: </label>
              <TextField
                id="endDate"
                name="endDate"
                type="date"
                variant="standard"
                value={currentSchedule.startDate}
                disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: currentSchedule.startDate, // Set minimum selectable date here
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "3vmin",
                    fontSize: "1.5vmin",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0",
                  },
                }}
                // onChange={handleScheduleChange}
              />
              <TextField
                id="endTime"
                name="endTime"
                type="time"
                variant="standard"
                value={currentSchedule.endTime}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "3vmin",
                    fontSize: "1.5vmin",
                    width: "12vmin",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0",
                  },
                }}
                onChange={handleScheduleChange}
              />
              <TimezoneSelect
                value={currentSchedule.timeZone}
                onChange={(event) => {
                  schedule.timeZone = event.value;
                  setCurrentSchedule((prev) => ({
                    ...prev,
                    timeZone: event.value,
                  }));
                }}
              />
            </div>
          </div>
        ) : (
          <div className="normal-schedule-content">
            <span>Once: &nbsp;</span>
            {currentSchedule.description}
          </div>
        )}
        <div className="schedule-buttons">
          {(requestType === "new" || requestType === "request") && (
            <IconButton
              color="primary"
              aria-label="recurring"
              style={{ fontSize: "1vmin" }}
              onClick={() =>
                handleMakeRecurringSchedule(
                  currentSchedule,
                  handleDataFromRecurrence
                )
              }
            >
              <RepeatIcon style={{ fontSize: "1.2vmin" }} />
              Make Recurring
            </IconButton>
          )}
          {requestType === "registered" && (
            <IconButton
              color="primary"
              aria-label="once"
              style={{ fontSize: "1vmin" }}
              onClick={() => setShowEditNormal(true)}
            >
              <RepeatIcon style={{ fontSize: "1.2vmin" }} />
              Edit Once
            </IconButton>
          )}

          {!courseRequest?.gibbonCourseClassID ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <Button
              variant="outlined"
              style={{
                width: "10vmin",
                fontSize: "1vmin",
              }}
              onClick={() => handleNewSchedule(currentSchedule)}
            >
              Save New Schedule
            </Button>
          )}
        </div>
      </div>
    );
  }

  const getScheduleContent = (data) => {
    var description = "";
    if (data.scheduleType === "once") {
      description = `On ${data.startDate} from ${data.startTime} to ${
        data.endTime
      } ${parseTimezone(data.timeZone).abbrev}`;
    } else {
      switch (data.frequency) {
        case "daily":
          description = `Every ${data.interval} day(s)`;
          break;
        case "daily-weekday":
          description = "Every weekday";
          break;
        case "weekly":
          description = `Every ${data.interval} week(s) on ${data.byDate}`;
          break;
        case "monthly-day":
          description = `Day ${data.byDay} of every ${data.interval} month(s)`;
          break;
        case "monthly-date":
          description = `The ${convertToOrder(data.byDay)} ${
            data.byDate
          } of every ${data.interval} month(s)`;
          break;

        case "yearly-day":
          description = `Every ${data.interval} year(s) on ${data.byMonth} ${data.byDay}`;
          break;

        case "yearly-date":
          description = `Every ${data.interval} year(s) on the ${convertToOrder(
            data.byDay
          )} ${data.byDate} of ${data.byMonth}`;
          break;
        default:
          break;
      }
      description =
        description +
        ` ${data.startTime} - ${data.endTime} ${
          parseTimezone(data.timeZone).abbrev
        } from ${data.startDate}`;

      if (data.endDate) {
        description = description + ` to ${data.endDate}`;
      } else if (data.numOccurrences) {
        description = description + ` for ${data.numOccurrences} occurrences`;
      }
    }
    return description;
  };

  function RecurringSchedule({ schedule }) {
    console.log(schedule);
    const [currentSchedule, setCurrentSchedule] = useState(schedule);
    const handleDataFromRecurrence = (data) => {
      // recieve data from classRecurrenceModel
      setCurrentSchedule((prevData) => ({
        ...prevData,
        ...data,
        scheduleType: "recurring",
        description: getScheduleContent(data),
      }));
      Object.entries(data).forEach(([key, value]) => {
        schedule[key] = value;
      });
      schedule.scheduleType = "recurring";
      schedule.description = getScheduleContent(data);
      updateSchedulesRecurring(data);
      // setClassForm((prev) => ({
      //   ...prev,
      //   ...data,
      //   scheduleType: "recurring",
      //   description: getScheduleContent(data),
      // }));
    };

    return (
      <div className="schedule-row">
        <div className="recurring-schedule">
          <span>Recurrence: &nbsp;</span>
          {currentSchedule.description}
        </div>
        <div className="schedule-buttons">
          {requestType !== "upcoming" && (
            <IconButton
              color="primary"
              aria-label="recurring"
              style={{ fontSize: "1vmin" }}
              onClick={() =>
                handleMakeRecurringSchedule(
                  currentSchedule,
                  handleDataFromRecurrence
                )
              }
            >
              <RepeatIcon style={{ fontSize: "1.2vmin" }} />
              {requestType === "request" ? "Make Recurring" : "Edit Recurrence"}
            </IconButton>
          )}
          {!courseRequest?.gibbonCourseClassID ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <Button
              variant="outlined"
              style={{
                width: "10vmin",
                fontSize: "1vmin",
              }}
              onClick={() => handleNewSchedule(currentSchedule)}
            >
              Save New Schedule
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="create-edit-class-modal">
      <div className="create-edit-class-container">
        <div className="create-edit-class-header">
          <h3>Create/Edit Class</h3>
          <button
            style={{ background: "white", border: "none", cursor: "pointer" }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        {loading ? (
          <div className="create-edit-class-container">
            <p>Loading...</p>
            <br />
            <br />
          </div>
        ) : (
          <div className="create-edit-class-content">
            <p
              style={{
                marginTop: "0",
                fontSize: "1.5vmin",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              * For requests, the request status will be changed to "solved".
              Refresh and edit in "Registered classes" to change for all future
              classes and/or in "Upcoming classes" to change one scheduled
              class.
            </p>

            <form>
              {courseRequest?.gibbonCourseClassID ? (
                <div style={{ display: "none" }}></div>
              ) : (
                <div className="create-edit-class-row">
                  <label htmlFor="purpose">Purpose: </label>
                  <select
                    id="purpose"
                    name="purpose"
                    value={classForm.purpose}
                    onChange={handleChange}
                    disabled={
                      !courseRequest?.gibbonCourseClassID &&
                      courseRequest?.svtRequestID
                    }
                  >
                    <option value="tutorial">Tutorial</option>
                    <option value="private credit">Private Credit</option>
                  </select>
                </div>
              )}
              {(!courseRequest?.gibbonCourseClassID &&
                courseRequest?.svtRequestID) ||
              courseRequest?.gibbonCourseClassID ? (
                <div style={{ display: "none" }}></div>
              ) : (
                <div className="create-edit-class-row">
                  <label htmlFor="learning-area">Learning area: </label>
                  <select
                    id="learning-area"
                    name="departmentName"
                    value={classForm.departmentName}
                    onChange={handleChange}
                  >
                    <option value="" disabled={true}>
                      Select a Learning Area
                    </option>
                    {[
                      ...new Set(
                        allCourses.map((course) => course.departmentName)
                      ),
                    ]
                      .sort()
                      .map((department) => (
                        <option key={department} value={department}>
                          {department}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="create-edit-class-row">
                <label htmlFor="course-name">Course Name: </label>
                {(!courseRequest?.gibbonCourseClassID &&
                  courseRequest?.svtRequestID) ||
                courseRequest?.gibbonCourseClassID ? (
                  <span>{classForm.courseName}</span>
                ) : (
                  <select
                    id="course-name"
                    name="courseName"
                    value={classForm.courseName}
                    onChange={handleChange}
                    required
                    disabled={
                      !classForm.departmentName ||
                      (!courseRequest?.gibbonCourseClassID &&
                        courseRequest?.svtRequestID) ||
                      courseRequest?.gibbonCourseClassID
                    }
                  >
                    <option value="" disabled={true}>
                      Select a Course
                    </option>
                    {allCourses
                      .filter(
                        (course) =>
                          course.departmentName === classForm.departmentName
                      )
                      .map((course) => (
                        <option key={course.courseID} value={course.courseID}>
                          {course.courseName}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              {(!courseRequest?.gibbonCourseClassID &&
                courseRequest?.svtRequestID) ||
              !courseRequest?.gibbonCourseClassID ||
              requestType === "upcoming" ? (
                <div style={{ display: "none" }}></div>
              ) : (
                <div className="create-edit-class-row">
                  <label htmlFor="class-name">Class Name: </label>

                  <span>{classForm.className}</span>
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "1.5vmin",
                      marginRight: "1.5vmin",
                      fontSize: "1vmin",
                      height: "90%",
                    }}
                    onClick={handleStopClass}
                  >
                    Stop Class
                  </Button>
                </div>
              )}
              <div className="create-edit-class-row">
                <label htmlFor="student">Student: </label>

                {courseRequest?.gibbonCourseClassID ? (
                  <span>{courseRequest.studentNames}</span>
                ) : (
                  <Autocomplete
                    disabled={
                      (!courseRequest?.gibbonCourseClassID &&
                        courseRequest?.svtRequestID) ||
                      courseRequest?.gibbonCourseClassID
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "4vmin",
                        fontSize: "1.5vmin",
                      },
                    }}
                    popupIcon={
                      <ArrowDropDownIcon style={{ fontSize: "2vmin" }} />
                    }
                    clearIcon={<CloseIcon style={{ fontSize: "1vmin" }} />}
                    id="studentList"
                    className="student-list"
                    options={allStudents}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.surname}, ${option.email}`
                    }
                    value={classForm.student}
                    onChange={(event, option) => {
                      setClassForm((prevForm) => ({
                        ...prevForm,
                        student: option,
                        studentID: option?.gibbonPersonID,
                      }));
                    }}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} style={{ fontSize: "1.5vmin" }}>
                          {`${option.firstName} ${option.surname}, ${option.email}`}
                        </span>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="none"
                        className="student-picker"
                        placeholder="Select a student"
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "3vmin",
                            fontSize: "1.5vmin",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div className="create-edit-class-row">
                <label htmlFor="teacher">Teacher: </label>
                <select
                  disabled={requestType === "upcoming"}
                  id="teacher"
                  name="teacherID"
                  value={classForm.teacherID}
                  onChange={(event) => {
                    const { value } = event.target;
                    const teacher = allTeachers.find(
                      (t) => t.gibbonPersonID === parseInt(value)
                    );

                    setClassForm((prevForm) => ({
                      ...prevForm,
                      teacher: teacher,
                      teacherID: value,
                    }));
                  }}
                >
                  <option value="" disabled={true}>
                    Select a teacher
                  </option>
                  {allTeachers.map((teacher) => (
                    <option
                      key={teacher.gibbonPersonID}
                      value={teacher.gibbonPersonID}
                    >
                      {`${teacher.firstName} ${teacher.surname} ${teacher.email}`}
                    </option>
                  ))}
                </select>
                {!courseRequest?.gibbonCourseClassID ||
                requestType === "upcoming" ? (
                  <div style={{ display: "none" }}></div>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "1.5vmin",
                      marginRight: "1.5vmin",
                      fontSize: "1vmin",
                      height: "90%",
                    }}
                    onClick={handleNewTeacher}
                  >
                    Save New Teacher
                  </Button>
                )}
              </div>
              <div className="create-edit-class-row">
                <label htmlFor="location">Location: </label>
                <select
                  disabled={requestType === "upcoming"}
                  id="location"
                  name="locationID"
                  value={classForm.locationID}
                  onChange={handleChange}
                >
                  <option value="" disabled={true}>
                    Select a location
                  </option>
                  {allLocations.map((location) => (
                    <option
                      key={location.gibbonSpaceID}
                      value={location.gibbonSpaceID}
                    >
                      {`${location.type}: ${location.name}`}
                    </option>
                  ))}
                </select>
                {!courseRequest?.gibbonCourseClassID ||
                requestType === "upcoming" ? (
                  <div style={{ display: "none" }}></div>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "1.5vmin",
                      marginRight: "1.5vmin",
                      fontSize: "1vmin",
                      height: "90%",
                    }}
                    onClick={handleNewLocation}
                  >
                    Save New Location
                  </Button>
                )}
              </div>
              <div className="create-edit-class-row">
                <label htmlFor="schedule">Schedule: </label>
                <ScheduleSection />
              </div>

              {courseRequest?.gibbonCourseClassID ? (
                <div>
                  <br />
                  <br />
                </div>
              ) : (
                <div className="create-edit-class-save">
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "1.5vmin",
                      marginRight: "1.5vmin",
                      fontSize: "1.2vmin",
                    }}
                    onClick={handleNewClass}
                  >
                    Save New Class
                  </Button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
      {showRecurrence && (
        <ClassRecurrenceModal
          setShowModal={setShowRecurrence}
          classForm={scheduleToEdit}
          sendBack={handleDataFromRecurrence}
        />
      )}
    </div>
  );
}
export default CreateEditClassModal;
