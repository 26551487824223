import axios from "axios";
import { GlobalConstant } from "./GlobalConstant";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? GlobalConstant.REACT_APP_PROD_BACKENDURL
    : GlobalConstant.REACT_APP_DEV_BACKENDURL;

const axiosClient = axios.create({
  baseURL: apiUrl,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      const { response } = error;
      if (response.status === 401) {
        localStorage.removeItem("ACCESS_TOKEN");
      }
    } catch (err) {
      console.error(err);
    }
    throw error;
  }
);

export default axiosClient;
